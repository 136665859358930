import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { SwiperSlide } from 'swiper/react'
import Icon from '../../atoms/icon'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled(Box, { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: var(--spacing-6);
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	color: var(--color-white);
	font-size: ${({ theme }) => theme.typography.pxToRem(29)};
	${({ action }) => (action === 'prev' ? 'left: 0;' : 'right: 0;')}

	&.swiper-button-disabled {
		pointer-events: auto;
		display: none;
	}
	background-color: var(--color-white);
	border: 1px solid transparent;
	border-radius: 50%;
	color: var(--color-primary);
	padding: 0;

	&.swiper-button-prev {
		left: 14px;
	}
	&.swiper-button-next {
		right: 14px;
	}
	&.swiper-button-lock {
		display: none;
	}

	&.swiper-button-lock {
		display: none;
	}
`

const PaginationStyled = styled('div')`
	position: relative;
	margin-top: var(--spacing-3);
	line-height: 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: var(--spacing-4);
	}
	&.swiper-pagination-horizontal {
		bottom: 0px;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
			bottom: var(--spacing-4);
		}
	}

	&.swiper-pagination-lock {
		display: none;
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	.swiper-pagination-bullet {
		--swiper-pagination-bullet-horizontal-gap: 4px;
		background-color: transparent;
		height: auto;
		margin: 0 var(--swiper-pagination-bullet-horizontal-gap);
		opacity: 1;
		padding: 0;
		width: auto;
		&:before {
			background-color: transparent;
			border: 1px solid var(--color-primary);
			border-radius: 50%;
			content: '';
			display: block;
			height: var(--spacing-1);
			width: var(--spacing-1);
		}
		&-active {
			cursor: default;
			&:before {
				background-color: var(--color-primary);
			}
		}
	}

	&.swiper-pagination-lock {
		display: none;
	}
`

const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
`

const ScrollbarStyled = styled('div')`
	/* Edit swiper variables in css-baseline.js */
	&.swiper-scrollbar {
		background: ${({ theme }) => `var(--swiper-scrollbar-bg-color, ${theme.palette.grey.lighter})`};
		width: calc(100% - (var(--swiper-scrollbar-sides-offset, 1%) * 2));
		left: var(--swiper-scrollbar-sides-offset, 1%);
		right: var(--swiper-scrollbar-sides-offset, 1%);
		bottom: var(--swiper-scrollbar-bottom, 0px);
		z-index: 1;
		.swiper-scrollbar-drag {
			background: ${({ theme }) => `var(--swiper-scrollbar-drag-bg-color, ${theme.palette.grey.main})`};
			border-radius: ${({ theme }) => `var(--swiper-scrollbar-border-radius, ${theme.shape.borderRadius}px)`};
			cursor: grab;
		}
	}
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled, ScrollbarStyled }
